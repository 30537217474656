<template>
    <section>
        <vue-headful title="FLPN Aviasolutions - Home"></vue-headful>
        <div class="columns">
            <div class="column"></div>
        </div>    
    </section>
</template>
<script>
export default {
    name: 'Home'
}
</script>